import React from "react";
import SectionTitle from "./sectiontitle";
import SocialLinks from "./sociallinks";
import "../style/contact.less";

class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            submitDisabled: false
        };
    }
    render() {
        return (
            <section id="contact" className="container">
                <div className="section-title">
                    <SectionTitle title="Termine" />
                </div>
                <div className="row no-form">
                    <div className="col s12">
                        <h5 class="date-header">
                            Termine 2025
                        </h5>
                        <ul className="dates">
                            <li>vorherige Termine sind bereits <strong>ausgebucht</strong></li>
                            <li>Mittwoch, 26. März 2025, 18 Uhr</li>
                            <li>Sonntag, 6. April 2025, 15 Uhr</li>
                            <li><strong>Sonntag, 27. April 2025, 10-15 Uhr <br /> YOGA Meets Wildkraeuter, 89€, in Begleitung 79€/pers.</strong></li>
                            <li>Sonntag, 11. Mai 2025, 15 Uhr</li>
                            <li>Dienstag, 27. Mai 2025, 18 Uhr</li>
                            <li><strong>Sonntag, 15. Juni 2025, 10-15 Uhr <br /> YOGA Meets Wildkraeuter, 89€, in Begleitung 79€/pers.</strong></li>
                            <li>Mittwoch, 25. Juni 2025, 18 Uhr</li>

                            oder nach Vereinbarung
                            <li className="price">
                                <strong>49€ pro Person</strong>
                            </li>
                            <li>Dauer: ca. 3 Stunden</li>
                            <li>inkl. kleine Führung mit Sammlung,</li>
                            <li>Getränken, Speisen, gemeinsames Kochen, Infos</li>
                            <li className="price"><strong>auch als Gutschein erhältlich!</strong></li>
                        </ul>
                        <h6 className="social-header">Informationen unter:</h6>
                        <ul>
                            <li>
                                <SocialLinks />
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        );
    }
}

export default Contact;
